import React,{useState } from 'react';
import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import styles from 'css_modules/Modal.module.css';
import {ItemsHeader} from 'components/ItemsHeader';
import {ItemRow} from 'components/ItemRow';
import {FieldArray, useFormikContext} from 'formik';


export const ItemsBulkModifyModal = ({closeModal, confirmModal, taxRates, filteredCentres, filteredActivities, 
                                        filteredContracts,filteredVatClassification, filteredVatKvClassification,
                                        filteredAccountCode, doctype,user,checkedItems,allItemsChecked }) => {
    
    
                                
    const {values} = useFormikContext();
    
    
    let panewidth =  sessionStorage.getItem("prevPaneDetail"+user.pk)
    const [width, setWidth] = useState('auto');
    const [backupItems, setBackupItems] = useState([]);
    const [backupCosts, setBackupCosts] = useState([]);

    if (backupItems.length===0){setBackupItems(JSON.stringify(values.items))}
    if (backupCosts.length===0){setBackupCosts(JSON.stringify(values.costs))}
    panewidth = (100-panewidth)/100*window.innerWidth
    panewidth = panewidth >1200 ? 1200 : panewidth
    panewidth = panewidth < 900 ? 900 : panewidth
    
    let w = 'calc('+panewidth+'px - 5rem)'// ((100 - panewidth)/100*window.innerWidth-80)+"px"
    
    if (width !== w){ setWidth( w );}
    
    sessionStorage.setItem("backupItems",backupItems)
    sessionStorage.setItem("backupCosts",backupCosts)
    
    checkedItems =  checkedItems.sort().map(i => i + 1);
    let chckditms = [...checkedItems]
    chckditms = "("+chckditms.slice(0, 10).join(", ");

    if (checkedItems.length > 10) {
        chckditms += ", ...";
    }
    chckditms +=")"
        
    if (allItemsChecked===true){chckditms="(vše)"}
    return (
        <FieldArray name="items">
                {arrayHelpers => (
          <>
            <div className={styles.ontop} styles={{background: "red"}} >
                <Modal name={"Hromadná úprava položek " + chckditms} optionalWidth={width} close={closeModal} itemsEdit={true} >
                    <div style={{padding:'0.5em',paddingBottom:'0.0em'}}>
                        <table style={{ border: "1px solid var(--semilight)",background: "var(--light)"}}>
                            <thead>
                                <tr>
                                    <th className={styles.headitemstd1}>
                                        <ItemsHeader editable={false} noCheck={true}/>
                                    </th>
                                </tr>
                            </thead>  
                            <tbody className={styles.itemstablebody}>
                                <tr className={styles.itemstr}>
                                    <td className={styles.itemstd1}>
                                
                                 <ItemRow
                                        key={values.items.length-1}
                                        editable={true}
                                        bulkEdit={true}
                                        index={values.items.length-1}
                                        taxRates={[...taxRates]}                                
                                        filteredCentres={[ ...filteredCentres]}
                                        filteredActivities={[...filteredActivities]}
                                        filteredContracts={[...filteredContracts]}
                                        filteredVatClassification={[...filteredVatClassification]}
                                        filteredVatKvClassification={[...filteredVatKvClassification]}
                                        filteredAccountCode={[...filteredAccountCode]}
                                        doctype={doctype}
                                />
                                    
                                    </td>
                                </tr>
                            </tbody>
                        </table>  
                        <div style={{ display: 'flex', alignItems: 'center',paddingTop:"0em" }}>
                            <div style={{ flexGrow: 1, textAlign: 'left' , color: 'var(--dark)' , fontSize: '0.9em', paddingLeft:'0.1em', paddingBottom:'0.2em' }}>
                                Zadané změny se provedou u vybraných položek. <br></br> Obsah textového pole smažete stisknutím backspace nebo delete.
                            </div>
                            <Button
                                disabled={false}
                                className={styles.buttonleft}
                                onClick={closeModal}
                            >
                                Zrušit
                            </Button>
                            <Button
                                disabled={false}
                                className={styles.buttonright}
                                onClick={confirmModal}
                                primary
                            >
                                Provést změny
                            </Button>
                            </div>
                    </div>
                </Modal>
            </div>
        </>
        )}
        </FieldArray>
    )
};
