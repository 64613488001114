import React,{useState,useRef,useEffect} from 'react';
import {NavLink, useHistory, useParams, useLocation } from 'react-router-dom';
import {useFormikContext} from 'formik';

import {useModal, useFetch,useToast} from 'helpers/hooks';
import {ActionButton} from 'components/ActionButton';
import {Button} from 'components/Button';
import {CaptureButton} from 'components/CaptureButton';
import {EditForm} from 'components/EditForm';
import {FilePreview} from 'components/FilePreview';
import {RejectModal} from 'components/RejectModal';
import {RemoveAssignedModal} from "./RemoveAssignedModal";
import {RemoveModal} from 'components/RemoveModal';

import {SaveModal} from './SaveModal';

import Split from '@uiw/react-split';

//mport {ReactComponent as LeftIcon} from 'assets/svg/chevron_left.svg';
//import {ReactComponent as RightIcon} from 'assets/svg/chevron_right.svg';

import styles from 'css_modules/Detail.module.css';

export const Detail = (
    {canCapture, canEdit, detail, doc, setDocUuid, permissions, role,user, setForwarding, requiredFields, setDuplicates,formInitValues, page, ...props}
) => {
    const history = useHistory();
    let {table, uuid} = useParams();
    let location = useLocation();
    let [nextLocation,setNextLocation] = useState("")
    const {submitForm, validateForm, values} = useFormikContext();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const resizeHandler = () => {
          if (windowWidth!==window.innerWidth){ setWindowWidth(window.innerWidth);
              
          }
        };
        
        window.addEventListener('resize', resizeHandler);
    
        return () => {
          window.removeEventListener('resize', resizeHandler);
        };
      }); 
  

    let docsList = sessionStorage.getItem("docsList");

    const [unassignModal, toggleUnassign] = useModal();
    const [rejectModal, toggleReject] = useModal();
    const [removeModal, toggleRemove] = useModal();

    const [search] = useState('')
    const [ordering] = useState('')
    
    const currentTable = location.pathname.split("/")[1];
    let docs = useFetch(
        '/'+currentTable+`/?search=${search}&ordering=${ordering}&page=${1}&limit=50`,
        null
    );
    
    let [docCustomType,setDocCustomType] = useState("")

    let cdt = useFetch('/get_user_type_for_documents/?documents='+[doc.uuid])
    
    
    
    if (cdt?.data?.[0]?.user_types?.[0]?.id && docCustomType==="" ){
        setDocCustomType(cdt.data[0].user_types[0].id)
        //console.log('setting doctype from network to ' + cdt.data[0].user_types[0].id)
    }


    if (docsList){
        docsList = docsList.split(",");
    }
    else {
        docsList=[]
        if (docs.data){
            for (let i=0; i<docs.data?.results.length; i++){
                let el = docs.data?.results[i]
                docsList.push(el["uuid"])
            }
        }
    }
    
    let newuuids = []
    if (sessionStorage.getItem("newUuids")){
        newuuids = sessionStorage.getItem("newUuids").split(',')
    }
    
    if (newuuids.length>0){
        newuuids.forEach(e => {
            if (!docsList.includes(e)){
                docsList.unshift(e)
            }
        })
    }

    
    const isAccountant = role === 'AccountantUser';
    const isCashDeskDocType = ['ppr', 'pvy'].includes(values.info.document_type);
    const activeAndNotWaiting = doc.is_active && !doc.awaiting_capture;
    const rejectButton = permissions.reject && activeAndNotWaiting  //&& doc.added_by_client;
    const removeButton = permissions.remove && activeAndNotWaiting;
    const previousDocButton = true && table!=="my_templates";
    const nextDocButton =  true  && table!=="my_templates";
    const captureButton = canCapture && activeAndNotWaiting && !doc.captured;
    const unassignButton = permissions.accessDocuments && activeAndNotWaiting;
    const isOrgUnitAdmin = role === "OrganizationUnitAdmin";
    const primaryButtonIsVisible = (table === 'documents' || activeAndNotWaiting) && !isOrgUnitAdmin ;
 

    let myidx = docsList.findIndex(obj => obj === doc.uuid)  
    let nextuuid = undefined
    let prevuuid = undefined
   
    

    if (myidx!==-1){
        try{
            prevuuid = docsList[myidx+1]
            nextuuid =docsList[myidx-1]
        }
        catch (error){
        }
    }
    
    const editFormRef = useRef(null)
    
    let myloc = window.location.href.split("/")[window.location.href.split("/").length-1]
    const [targetuuid, setTargetUUID] = useState('')
    const [savemodal, togglesavemodal] = useModal();
    let [differences, setDifferences] = useState([]);
    let forceMove = false

    const saveFunction = () => {
        editFormRef.current.onSaveButtonClick()
        setDifferences(-1)
        console.log("newdiffs")
        console.log(differences)
    }

    
  useEffect(() => {
    const unlisten = history.block((location) => {
        setNextLocation(location.pathname)
        let diff = editFormRef.current.isSaveNeeded(formInitValues)
        
        console.log(sessionStorage.getItem("forceMove"))
        if (differences!==-1 && sessionStorage.getItem("forceMove")!== "0" && diff.length > 0  && forceMove===false && !location.pathname.includes(doc.uuid)) {
            console.log("set diff")
            setDifferences(diff)
            togglesavemodal()
            return false;
        }
     return true;
    });

    return () => {
      unlisten();
    };
  }, [formInitValues,history,togglesavemodal,forceMove,doc.uuid,differences]);
  
  const redirectAway = () => {
    //console.log('redirecting')
    //console.log(nextLocation)
    if (targetuuid!==''){
        let t = targetuuid==='next' ? nextuuid : prevuuid
        history.replace('/'+currentTable+'/'+t+'/'+myloc, '')
        window.location.reload()
    }
    else{
        //console.log('redirect ' + nextLocation)
        history.replace(nextLocation,'')
        window.location.reload()
    }
    forceMove = false
  }

  useEffect (() =>{
    //console.log('target changed ' + targetuuid)
  },[targetuuid])
  

    const modalClickedSave = () => {
        forceMove=true
        editFormRef.current.onSaveButtonClick()
        showToast('Uloženo');
        togglesavemodal();
        redirectAway()
    }

    const modalClickedClose = () => {
        forceMove=true
        togglesavemodal();
        redirectAway()
    }
    
    const showToast = useToast();
    const goPrev =()=>{goPrevious()}
    const goNex =()=>{goNext()}
    const goPrevious = () => {
        //console.log('goprev ' + prevuuid)
        let diff = editFormRef.current.isSaveNeeded(formInitValues)
        setDifferences(diff)

        if (sessionStorage.getItem("saverequired")==="1"){
            setTargetUUID('prev')
            togglesavemodal();
        }
        else{
            history.push('/'+currentTable+'/'+prevuuid+'/'+myloc, '')
            //update(prevuuid)
            //if (!['general', 'costs','metadata'].includes(myloc))
             window.location.reload()
        }
    
    }

    const goNext = () => {
        //console.log('gonext ' + nextuuid)
        let diff = editFormRef.current.isSaveNeeded(formInitValues)
        setDifferences(diff)

        if (sessionStorage.getItem("saverequired")==="1"){
            setTargetUUID('next')
            togglesavemodal();
        }
        else{
            history.push('/'+currentTable+'/'+nextuuid+'/'+myloc, '')
            //update(nextuuid)
            //if (!['general', 'costs','metadata'].includes(myloc))
            window.location.reload()
        }
        
    }

    const update = (newuuid) => {
        //setDocUuid(newuuid);
        //formInitValues=values
        //detail.fetchData(); // update history
    }

    const [previewDoc, setPreviewDoc] = useState(doc)
    const [lastResponse, setLastResponse] = useState(doc)
    const [refresh, setRefresh] = useState(false);

   

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const delay = async () => {
            await new Promise(resolve => setTimeout(resolve, 5000));
           
          };

        delay()
        if (refresh===true){
        const fetchData = async () => {
            try {
              const url = `/${table}/${uuid}/`
                const response = await fetch(`${process.env.REACT_APP_API}${url}`, {
                headers: {
                  'Authorization': 'JWT ' + localStorage.getItem('DocspointJWT'),
                  'Content-Type': 'application/json'
                },
              });

              
              let resp =  await response.json();
             
              if (JSON.stringify(lastResponse) !== JSON.stringify(resp)){
                setLastResponse(resp)
                setPreviewDoc(resp);
              }
              
              
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
          fetchData();
          setRefresh(false)
        }
    }, [table,uuid,refresh,lastResponse]); 

    const updateFile = async () => {
       setRefresh(true);
   }

    const redirect = () => history.push('/my_documents');
        
    let pageTitle = doc.name;
    if (detail.data.info.state==="tpl"){
        pageTitle = "Šablona: " + doc.document_info['template_name'];
    }
    else if (doc.document_info['document_identifier']){
        pageTitle = doc.document_info['document_identifier'];
    }

    const divRef = useRef(null);
    let prevPane = parseFloat(sessionStorage.getItem("prevPaneDetail"+user.pk))
    if (prevPane!==undefined && !isNaN(prevPane)){  }
    else{  
         prevPane=40 
         sessionStorage.setItem("prevPaneDetail"+user.pk, 40);
     }
    const handleResize = (preSize, nextSize, paneNumber) => {
        sessionStorage.setItem("prevPaneDetail"+user.pk, nextSize);
        console.log(nextSize)
    };
    let minWidthLeft = 41
    if (values.items.length===0){minWidthLeft = 37}

    if (table==="my_templates"){prevPane=30}


    if (windowWidth > 1224){
        return (
            <>
            <div className={styles.wrap}>
                <header className={styles.topRow}>
                    <h1 className={styles.heading}>{pageTitle}</h1>
                    <div className={styles.actionButtons}>
                        {captureButton &&
                            <CaptureButton
                                className={styles.secondaryButton}
                                docs={[doc]}
                                update={redirect}
                                saveFunction={saveFunction}
                            />
                        }
                        {unassignButton && <Button onClick={toggleUnassign} className={styles.secondaryButton}>Zrušit přiřazení</Button>}
                        {rejectButton && <Button onClick={toggleReject} className={styles.secondaryButton}>Vrátit</Button>}
                        {removeButton && <Button onClick={toggleRemove} className={styles.secondaryButton}>Smazat</Button>}
                        {primaryButtonIsVisible &&
                        <ActionButton
                            className={styles.actionButton}
                            forward={doc.is_active}
                            data={uuid}
                            canEdit={canEdit}
                            submitForm={submitForm}
                            validateForm={validateForm}
                            setForwarding={setForwarding}
                            user={user}
                            saveFunction={saveFunction}
                            miscellaneousDocType={doc.document_info.document_type==='rzn'}
                            detaildoc={doc}
                        />
                        }
                        <div className={styles.prevNextCouple}>
                        {savemodal && <SaveModal clickedSave={modalClickedSave} clickedClose={modalClickedClose} diff={differences} close={togglesavemodal} />}
                        {previousDocButton &&  <Button onClick={goPrev} disabled={!prevuuid}  className={styles.prevButton}> {'<'} </Button>}
                        {nextDocButton && <Button onClick={goNex}  disabled={!nextuuid}  className={styles.nextButton}>     {'>'} </Button>}
                        </div>
                    </div>
                    
                </header>
                <section className={styles.container}>
                    <Split onDragEnd={handleResize} visible={table!=="my_templates"} style={{height:`100%`}}>
                    <div ref={divRef} style={{ minWidth: `${minWidthLeft}em`, width: `${100 - prevPane}%`, marginRight: "1em" }}>
                        <div className={styles.main}>
                        <nav className={styles.navRow}>
                            <NavLink
                            to={`/${table}/${uuid}/general`}
                            activeClassName={styles.menuItemActive}
                            className={styles.menuItem}
                            >
                            Obecné
                            </NavLink>
                            <NavLink
                            to={`/${table}/${uuid}/supplier`}
                            activeClassName={styles.menuItemActive}
                            className={styles.menuItem}
                            >
                            Partner
                            </NavLink>
                            {!isCashDeskDocType && (
                            <NavLink
                                to={`/${table}/${uuid}/payment`}
                                activeClassName={styles.menuItemActive}
                                className={styles.menuItem}
                            >
                                Platba
                            </NavLink>
                            )}
                            <NavLink
                            to={`/${table}/${uuid}/costs`}
                            activeClassName={styles.menuItemActive}
                            className={styles.menuItem}
                            >
                            Náklady
                            </NavLink>
                            {(isAccountant || isOrgUnitAdmin) && (
                            <NavLink
                                to={`/${table}/${uuid}/accounting`}
                                activeClassName={styles.menuItemActive}
                                className={styles.menuItem}
                            >
                                Účtování
                            </NavLink>
                            )}
                            <NavLink
                            to={`/${table}/${uuid}/history`}
                            activeClassName={styles.menuItemActive}
                            className={styles.menuItem}
                            >
                            Historie
                            </NavLink>
                            <NavLink
                            to={`/${table}/${uuid}/metadata`}
                            activeClassName={styles.menuItemActive}
                            className={styles.menuItem}
                            >
                            Metadata
                            </NavLink>
                        </nav>
                        <EditForm
                            innerRef={editFormRef}
                            doc={doc}
                            detail={detail.data}
                            permissions={permissions}
                            isCashDeskDocType={isCashDeskDocType}
                            isAccountant={isAccountant || isOrgUnitAdmin}
                            requiredFields={requiredFields}
                            user={user}
                            update={update}
                            setDuplicates={setDuplicates}
                            docCustomType={docCustomType}
                            setDocCustomType={setDocCustomType}
                            {...props}
                        />
                        </div>
                    </div>
                    <div style={{  minWidth: "13em", width:`${prevPane}%`,marginLeft:"1em",position: "relative",zIndex:60}}>
                        {detail.data.info.state !== "tpl" && <FilePreview detailHeight={true} doc={previewDoc} refresh={updateFile} />}
                    </div>
                    </Split>

                </section>
                {unassignModal && <RemoveAssignedModal close={toggleUnassign} doc={doc} update={redirect} user={role}/>}
                {rejectModal && <RejectModal close={toggleReject} doc={doc} update={redirect}/>}
                {removeModal && <RemoveModal close={toggleRemove} doc={doc} update={redirect} user={role}/>}
                </div>
            </>
        )
    }
    else{
        return (
            <>
                <header className={styles.topRow}>
                    <h1 className={styles.heading}>{pageTitle}</h1>
                    <div className={styles.actionButtons}>
                        {captureButton &&
                            <CaptureButton
                                className={styles.secondaryButton}
                                docs={[doc]}
                                update={redirect}
                                saveFunction={saveFunction}
                            />
                        }
                        {unassignButton && <Button onClick={toggleUnassign} className={styles.secondaryButton}>Zrušit přiřazení</Button>}
                        {rejectButton && <Button onClick={toggleReject} className={styles.secondaryButton}>Vrátit</Button>}
                        {removeButton && <Button onClick={toggleRemove} className={styles.secondaryButton}>Smazat</Button>}
                        {primaryButtonIsVisible &&
                        <ActionButton
                            className={styles.actionButton}
                            forward={doc.is_active}
                            data={uuid}
                            canEdit={canEdit}
                            submitForm={submitForm}
                            validateForm={validateForm}
                            setForwarding={setForwarding}
                            user={user}
                            saveFunction={saveFunction}
                            miscellaneousDocType={doc.document_info.document_type==='rzn'}
                            detaildoc={doc}
                        />
                        }
                        <div className={styles.prevNextCouple}>
                        {savemodal && <SaveModal clickedSave={modalClickedSave} clickedClose={modalClickedClose} diff={differences} close={togglesavemodal} />}
                        {previousDocButton &&  <Button onClick={goPrev} disabled={!prevuuid}  className={styles.prevButton}> {'<'} </Button>}
                        {nextDocButton && <Button onClick={goNex}  disabled={!nextuuid}  className={styles.nextButton}>     {'>'} </Button>}
                        </div>
                    </div>
                    
                </header>
                <section className={styles.container}>
                    <div className={styles.main}>
                        <nav className={styles.navRow}>
                            <NavLink
                                to={`/${table}/${uuid}/general`}
                                activeClassName={styles.menuItemActive}
                                className={styles.menuItem}
                            >
                                Obecné
                            </NavLink>
                            <NavLink
                                to={`/${table}/${uuid}/supplier`}
                                activeClassName={styles.menuItemActive}
                                className={styles.menuItem}
                            >
                                Partner
                            </NavLink>
                            {!isCashDeskDocType && (
                                <NavLink
                                    to={`/${table}/${uuid}/payment`}
                                    activeClassName={styles.menuItemActive}
                                    className={styles.menuItem}
                                >
                                    Platba
                                </NavLink>
                            )}
                            <NavLink
                                to={`/${table}/${uuid}/costs`}
                                activeClassName={styles.menuItemActive}
                                className={styles.menuItem}
                            >
                                Náklady
                            </NavLink>
                            {(isAccountant || isOrgUnitAdmin) && (
                                <NavLink
                                    to={`/${table}/${uuid}/accounting`}
                                    activeClassName={styles.menuItemActive}
                                    className={styles.menuItem}
                                >
                                    Účtování
                                </NavLink>
                            )}
                            <NavLink
                                to={`/${table}/${uuid}/history`}
                                activeClassName={styles.menuItemActive}
                                className={styles.menuItem}
                            >
                                Historie
                            </NavLink>
                            <NavLink
                                to={`/${table}/${uuid}/metadata`}
                                activeClassName={styles.menuItemActive}
                                className={styles.menuItem}
                            >
                                Metadata
                            </NavLink>
                        </nav>
                        <EditForm
                            innerRef={editFormRef}
                            doc={doc}
                            detail={detail.data}
                            permissions={permissions}
                            isCashDeskDocType={isCashDeskDocType}
                            isAccountant={isAccountant || isOrgUnitAdmin}
                            requiredFields={requiredFields}
                            user={user}
                            update={update}
                            setDuplicates={setDuplicates}
                            docCustomType={docCustomType}
                            setDocCustomType={setDocCustomType}
                            {...props}
                        />
                    </div>
                    {detail.data.info.state!=="tpl" && <FilePreview doc={previewDoc} refresh={updateFile}/>}
                </section>
                {unassignModal && <RemoveAssignedModal close={toggleUnassign} doc={doc} update={redirect} user={role}/>}
                {rejectModal && <RejectModal close={toggleReject} doc={doc} update={redirect}/>}
                {removeModal && <RemoveModal close={toggleRemove} doc={doc} update={redirect} user={role}/>}
            </>
        )
    }
};