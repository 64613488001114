

import styles from 'css_modules/ItemsHeader.module.css';
import {ItemCell} from 'components/ItemCell';

export const ItemsHeader = ({editable,allItemsChecked,setAllItemsChecked,setCheckedItems}) => {
       
    const handleCheckbox = () => {
        if (!allItemsChecked===false){
            setCheckedItems([])
        }
        setAllItemsChecked(!allItemsChecked);
        
    }

    return (
        <>
        <div className={styles.rowwrapper}>

      
        <table className={styles.itemstable}>
        <tbody>
        <tr>
        <td className={styles.itemstd}>
        Č.
        </td>
        <td className={styles.polozka}>
            <td className={styles.nestedcell1}>
                Kód
            </td>
            <td className={styles.nestedcell2}>
                Položka
            </td>
        </td>
        <td className={styles.itemstd}>
        Množ.
        </td>
        <td className={styles.itemstd}>
        M.j.
        </td>
        <td className={styles.itemstd}>
        J. cena
        </td>
        <td className={styles.itemstd}>
        DPH</td>
        <td className={styles.itemstd}>
        Sleva %
        </td>
        <td className={styles.itemstd}>
        Částka
        </td>
        <td className={styles.itemstd}>
        DPH
        </td>
        </tr>

        <tr>
            {editable===true ? 
            <td className={styles.checkcell}>
                 <ItemCell type="checkbox" name={"check"}   style={{ padding:'none',margin:'none', border: 'none', outline: 'none' }}
                 checked={allItemsChecked} disabled={!editable} onChange={handleCheckbox} />
            </td>        
                :
                <td className={styles.itemstd}></td>
            }
        
        <td className={styles.itemstd}>
        Poznámka
        </td>
        <td className={styles.itemstd}>
        Pár. sym
        </td>
        <td className={styles.itemstd}>
        Předkontace
        </td>
        <td className={styles.itemstd}>
        PDP
        </td>
        <td className={styles.itemstd}>
        Čl. DPH
        </td>
        <td className={styles.acrosstwocolumns}>
        Středisko
        </td>
        <td className={styles.itemstd}>
        Činnost
        </td>
        <td className={styles.itemstd}>
        Zakázka
        </td>
        </tr>
        </tbody>
        </table>
        
        </div>
        </>
    )
};