import React from 'react';
import {Field, ErrorMessage} from 'formik';

import styles from 'css_modules/Input.module.css';

export const Input = ({children, className, disabled, inputClass, isHidden, isRequired, itemRow,...props}) => {
    const classNames = `${className} ${isHidden ? styles.hidden : null}`;
    //console.log(disabled)
    return (
        <label className={classNames}>{children}
            {isRequired && !disabled && <span title="Povinné pole" className={styles.asterisk}>*</span>}
            <Field className={inputClass} disabled={disabled} {...props}/>
            <div className={styles.errorPlaceholder}>
                <ErrorMessage name={props.name}>
                    {data =>
                        Array.isArray(data)
                            ? data.map((error, index) => <p key={index} className={styles.error}>{error}</p>)
                            : <p className={styles.error}>{data}</p>
                    }
                </ErrorMessage>
            </div>
        </label>
    )
};