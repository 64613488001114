import React, {useEffect, useState} from 'react';
import {FieldArray, useFormikContext} from 'formik';
import {Button} from 'components/Button';
import {Cost} from 'components/Cost';
import {FieldsWrapper} from 'components/FieldsWrapper';
import {Input} from 'components/Input'
//import {Select} from 'components/Select';
import styles from 'css_modules/Costs.module.css';
import {AutocompleteSelect} from 'components/AutocompleteSelect';
import {Items} from 'components/Items';

export const Costs = ({ currencies, editable, isAccountant, user, taxRates, log, 
                        clientCompanies, handlySetCurrency, setHandlySetCurrency,centres, 
                        activities,contracts,vatClassification,vatKvClassification,
                        accountCode,doc, docTypes, accountingYears,...props}) => {
    
    let currentuuid =sessionStorage.getItem("currentuuid")
    if (currentuuid !== doc.uuid){
        if (currentuuid!==''){
            //window.location.reload();
        }
    }
    
    sessionStorage.setItem("currentuuid", doc.uuid);
    const [refresh,setRefresh] = useState(true)
    const [filteredTaxRates, setFilteredTaxRates] = useState(taxRates);
    const [totalCost, setTotalCost] = useState(0);
    const {setFieldValue, values} = useFormikContext();

    const czkId = currencies ? currencies.find(currency => currency.slug === 'czk')?.id : null;
    const euroId = currencies ? currencies.find(currency => currency.slug === 'eur')?.id : null;
    const companyId = parseInt(values.info.client_company)
    const company = clientCompanies.filter(c => c.id === companyId)[0];
    const country = {slug: company?.country?.slug || 'cz'};
    let isCzk = parseInt(values.info.currency) === czkId;
    let isEur = parseInt(values.info.currency) === euroId;
    const currencyRequired =
        isAccountant && ['fpr', 'fvy', 'fvz', 'opo', 'oza', 'pvy', 'ppr'].includes(values.info.document_type)
    const disabledAdd = filteredTaxRates.length <= values.costs.length;
    
    const get_toggle_items = (companyId) =>{
        let currentCompany = clientCompanies.find(e => parseInt(e["id"]) === parseInt(companyId))
        if (!currentCompany){return 0}
        if (currentCompany && currentCompany["toggle_items"] && currentCompany["toggle_items"]===true){
            if (user["toggle_items"]===true){
                return true
            }
        }
        return false
    }
    let cc_items = get_toggle_items(values.info.client_company)
    let user_items = user.toggle_items
    const [toggleItems, setToggleItems] = useState(false);
    
    if (cc_items===true && user_items===true){if (toggleItems!==true){setToggleItems(true)}}
    if (cc_items===true && user_items===false){if (toggleItems!==false){setToggleItems(false)}}
    if (cc_items===false && user_items===true){if (toggleItems!==false){setToggleItems(false)}}
    if (cc_items===false && user_items===false){if (toggleItems!==false){setToggleItems(false)}}
    if (cc_items===0 && user_items===true){if (toggleItems!==true){setToggleItems(true)}}
    
    const orderArrayByRate= ( array) => {
        let orderedArray = []
        let bannedElements = []

        while (orderedArray.length !== array.length){
            let maxKeyVal = -1
            let maxid = -1
            for (let i = 0; i<array.length; i++){
                let a = array[i]
                let keyVal = a.rate
                if (keyVal > maxKeyVal  && !bannedElements.includes(i)){
                    maxKeyVal = keyVal
                    maxid = i
                }
            } 
            orderedArray.push(array[maxid])
            bannedElements.push(maxid)
        }
        return orderedArray
    }

    const getAvailableTaxRates = taxRate => {
        if (filteredTaxRates) {
            let rates = filteredTaxRates.filter(rate =>
                rate.id === parseInt(taxRate) || !values.costs.map(cost => parseInt(cost.tax_rate)).includes(rate.id));
            return orderArrayByRate(rates)
        }
        return []
    };

    const getUserFriendlyCurrencies = () => {
        const prefferedCurrencies = ['EUR', 'CZK', 'USD'];
        let userFriendlyCurrencies = [];
        
        prefferedCurrencies.forEach( (pc) => {
            userFriendlyCurrencies.push(currencies[currencies.indexOf(currencies?.find(curr => curr.title === pc))]) ;
            }
        )

        const blankCurrency = {"title" : "---", "id" : -1}
        userFriendlyCurrencies.push(blankCurrency)

        currencies.forEach( (curr) => {
                if (!userFriendlyCurrencies.includes(curr)){
                    userFriendlyCurrencies.push(curr) ;
                }   
            }
        )

        return userFriendlyCurrencies
    }
    const userFriendlyCurrencies = getUserFriendlyCurrencies()
    

    
    
    
    const getUsedTaxRates=() => {
        let usedTaxRates= [];
        values.costs.map(cost => usedTaxRates.push( taxRates?.find(rate => rate.id ===  parseInt(cost.tax_rate)  ))  ) ;
        
        return usedTaxRates.filter(item => item !== undefined);;
    }
    
 
    const emptyCost = () => {
        return {
            tax_rate: getAvailableTaxRates()[0].id,
            value_original: '',
            tax: '',
            value_final: ''
        };
    }

    if (values.costs.length===1){
        if (values.costs[0].tax_rate === ''){
            values.costs.splice(0, 1)
            values.costs.push(emptyCost())
        }
    }

    useEffect(() => {
        changeTaxRates()
        // eslint-disable-next-line
    }, [isCzk, taxRates, values.info.currency, isEur, country.slug, czkId, euroId]);

    const changeTaxRates = () => {
        //if (sessionStorage.getItem("itemsBulkModify")!=="true"){
            if (taxRates && czkId) {
                if (values.info.currency) {
                    let taxes = undefined
                    taxes = taxRates.filter(rate => rate.slug.startsWith(country.slug));
                    let taxesIds = taxes.map(item => {return item.id; });
                    const usedTaxRateIds = getUsedTaxRates().map(tax => tax.id);
                    const filteredTaxes = taxes.filter(tax => tax.is_active || usedTaxRateIds.includes(tax.id));
                    
                    taxes = Array.from(new Set(filteredTaxes.map(tax => tax.id)))
                    .map(id => filteredTaxes.find(tax => tax.id === id));
                    taxes = taxes.sort((a, b) => parseFloat(b.rate) - parseFloat(a.rate));

                    setFilteredTaxRates(taxes);

                    values.costs?.forEach((item, key) => {
                        if(!taxesIds.includes(parseInt(item.tax_rate))) {
                            values.costs[key].tax_rate = taxesIds[0]
                        }
                    })
                    values.items?.filter((item) => !item.hasOwnProperty("helperItem")).forEach((item, key) => {
                        if(!taxesIds.includes(parseInt(item.tax_rate))) {
                            values.items[key].tax_rate = taxesIds[0]
                        }
                    })
                }
            }
        //}
    }
    
    useEffect( () => {
        if (!handlySetCurrency && country.slug === 'sk'){
            setFieldValue('info.currency', euroId);
        }
    }, [handlySetCurrency, country.slug, setFieldValue, euroId])
    

    useEffect( () => {
        if (!values.costs) {
            return "";
        }
        const finalValues = values.costs.map(cost => parseFloat(cost.value_final) || 0);
        let rnd = values.info.rounding ? parseFloat(values.info.rounding) : 0
        let total = finalValues.reduce((total, current) => total + current, 0) + rnd
        total = total.toFixed(2).replace('.', ',');

        if (totalCost!==total){setTotalCost(total)}
    }, [values.costs,values.items,values.info.rounding,totalCost,values.items.length])

    const handleCurrencyChange = event => {
       setHandlySetCurrency(true);
       //handleChange(event);
    };

    
    
    return (
        <FieldArray name="costs">
            {arrayHelpers => (
                <>
                    <FieldsWrapper columns={2}>
                        <div style={{ width: '7rem' }}>
                            <Input
                                name="info.currency"
                                component={AutocompleteSelect}
                                data={userFriendlyCurrencies}
                                disabled={!editable}
                                isRequired={currencyRequired}
                                noBlankOption={true}
                                selectedValue={values.info["currency"]} 
                                onChange={(newValue) => {
                                    values.info["currency"] = newValue
                                    setRefresh(!refresh)
                                    handleCurrencyChange(newValue)
                                }} 
                                
                                
                            >
                                Měna
                            </Input>
                        </div>
                        {editable &&
                        <Button
                            disabled={disabledAdd}
                            onClick={() => arrayHelpers.push(emptyCost())}
                            className={styles.button}
                        >
                            Přidat nový náklad
                        </Button>
                        }
                    </FieldsWrapper>
                    <div className={styles.container}>
                        {values.costs?.map((cost, index) =>
                            <Cost
                                key={index}
                                index={index}
                                cost={cost}
                                taxRates={getAvailableTaxRates(cost.tax_rate)}
                                editable={editable}
                                isAccountant={isAccountant}
                                {...arrayHelpers}
                                {...props}
                            />
                        )}
                    </div>
                    {(false && values.costs && !!values.costs.length) && (
                        <FieldsWrapper columns={3}>
                            <dl className={styles.rounding}>
                             <Input
                                name={'info.rounding'}
                                type="number"
                                step="0.01"
                                disabled={!editable}
                                style={{ textAlign: 'right',width: '3em' }}
                            >
                                Zaokrouhlení
                        </Input>
                        </dl>
                        </FieldsWrapper>
                    )}
                    {values.costs && !!values.costs.length && (
                        <FieldsWrapper columns={2}>
                              <dl className={styles.total}>
                                <dt>Celková částka:</dt>
                                <dd>{totalCost}</dd>
                            </dl>
                        </FieldsWrapper>
                    )}

                    { true &&
                    <>
                    <Items  usedTaxRates={getUsedTaxRates()} 
                            taxRates={filteredTaxRates}
                            docTypes={docTypes}
                            doc={doc}
                            editable={editable}
                            accountingYears={accountingYears}
                            centres={centres}
                            activities={activities}
                            contracts={contracts}
                            vatClassification={vatClassification}
                            vatKvClassification={vatKvClassification}
                            accountCode={accountCode}
                            company={company}
                            toggleItems={toggleItems}
                            user={user}
                            {...arrayHelpers}
                            {...props}
                     />
                     </>
                    }
                </>
            )}

        
        </FieldArray>
    )
};