import {React,useState} from 'react';
import {useFormikContext} from 'formik';

import {useFilter, useRequired} from 'helpers/hooks';
import {FieldsWrapper} from 'components/FieldsWrapper';
import {Input} from 'components/Input';
//import {Select} from 'components/Select';
import {YesNo} from 'components/YesNo';
import {AutocompleteSelect} from 'components/AutocompleteSelect';

export const Accounting = (
    {user,accountCode, accountingYears, docTypes, editable, numSeries, vatClassification, vatKvClassification, fetchRequiredFields}
) => {
    const {handleChange,values} = useFormikContext();
    let requiredFields = fetchRequiredFields(user,values?.info["document_type"], values?.info["payment_type"])
    
    const [refresh,setRefresh] = useState(true)
    const numSeriesEditable = editable && values.info.client_company && values.info.document_type;
    const accountCodeEditable = editable && values.info.client_company && values.info.document_type;
    const vatClassificationEditable = editable && values.info.client_company && values.info.document_type;
    const vatKvClassificationEditable = editable && values.info.client_company && values.info.document_type;
    const vatClsFilterValue = docTypes.find((docType) => docType.slug === values.info.document_type)?.is_expense;

    const prefilteredNumSeries = useFilter(numSeries, {document_type: values.info.document_type});

    let doc_type = values.info.document_type;
    // eslint-disable-next-line
    switch(doc_type) {
        case 'vod':
            doc_type = 'fvy';
            break;
        case 'pod':
            doc_type = 'fpr'
            break;
    }
    const filteredAccountCode = useFilter(accountCode, {document_type: doc_type});
    const filteredVatClassification = useFilter(vatClassification, vatClsFilterValue === null?{}:{is_expense: vatClsFilterValue});
    const filteredVatKvClassification = useFilter(vatKvClassification, {document_type: doc_type});
    const numSeriesRequired = useRequired('num_series', requiredFields);
    const accountCodeRequired = useRequired('account_code', requiredFields);
    const vatClassificationRequired = useRequired('vat_classification', requiredFields);
    const vatKvClassificationRequired = useRequired('vat_kv_classification', requiredFields);

    const accountingYear = accountingYears.find((year) => year.id === parseInt(values.info.accounting_year))?.year;
    const filteredNumSeries = prefilteredNumSeries.filter(numSeries => numSeries.year >= accountingYear)

    const handleAccountCodeChange = (e) => {
        //handleChange(e)
        values.items.forEach((i)=>{
            if (!i["account_code"]){
                i["account_code"] = e
            }
        })
    }

    const handleVatClassificationChange = (e) => {
        //handleChange(e)
        values.items.forEach((i)=>{
            if (!i["vat_classification"]){
                i["vat_classification"] = e
            }
        })
    }

    const handleVatKVClassificationChange = (e) => {
        //handleChange(e)
        values.items.forEach((i)=>{
            if (!i["vat_kv_classification"]){
                i["vat_kv_classification"] = e
            }
        })
    }

    const handleReverseChargeChange = (e) => {
        handleChange(e)
        values.items.forEach((i)=>{
            if (!i["reverse_charge"]){
                i["reverse_charge"] = e.target.value
            }
        })
    }

    
    return (
        <FieldsWrapper columns={2}>
            <Input
                name="info.num_series"
                component={AutocompleteSelect}
                isRequired={numSeriesRequired}
                data={filteredNumSeries}
                disabled={!numSeriesEditable}
                selectedValue={values.info["num_series"]} 
                        onChange={(newValue) => {
                            values.info["num_series"] = newValue
                            setRefresh(!refresh)
                        }} 
                >
            
                Číselná řada
            </Input>
            <Input
                name="info.account_code"
                component={AutocompleteSelect}
                isRequired={accountCodeRequired}
                data={filteredAccountCode}
                disabled={!accountCodeEditable}
                selectedValue={values.info["account_code"]} 
                        onChange={(newValue) => {
                            values.info["account_code"] = newValue
                            handleAccountCodeChange(newValue)
                            setRefresh(!refresh)
                        }} 
                >
            
                Předkontace
            </Input>
            <Input
                name="info.vat_classification"
                component={AutocompleteSelect}
                isRequired={vatClassificationRequired}
                data={filteredVatClassification}
                disabled={!vatClassificationEditable}
                selectedValue={values.info["vat_classification"]} 
                onChange={(newValue) => {
                    values.info["vat_classification"] = newValue
                    handleVatClassificationChange(newValue)
                    setRefresh(!refresh)
                }} 
        
            >
                Členění DPH
            </Input>
            { filteredVatKvClassification.length > 0 ? <Input
                name="info.vat_kv_classification"
                component={AutocompleteSelect}
                isRequired={vatKvClassificationRequired}
                data={filteredVatKvClassification}
                disabled={!vatKvClassificationEditable}
                selectedValue={values.info["vat_kv_classification"]} 
                onChange={(newValue) => {
                    values.info["vat_kv_classification"] = newValue
                    handleVatKVClassificationChange(newValue)
                    setRefresh(!refresh)
                }}
            >
                Členění KV DPH
            </Input> : null }
            <YesNo name="info.reverse_charge" heading="Přenesená daňová povinnost" disabled={!editable} onChange={handleReverseChargeChange}/>
        </FieldsWrapper>
    )
};